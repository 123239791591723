* {
  box-sizing border-box
}

body {
  margin 0
  padding 0
  background $bcBlue
  color white
  overflow hidden
  position relative
}

.container {
  width 100vw
  height 100vh
  overflow hidden
  display flex
  flex-direction column
}

.guest {
  flex 1
  align-items center
  justify-content center
  display flex
  padding 0 2em
  position relative

  .liner {
    width 100%
    position relative
  }

  &:first-child {
    padding-top 1em
  }

  &:last-child {
    padding-bottom 1em
  }

  .guest-status span {
    background $bcRust
    color white
  }

  &.in .guest-status span {
    background $bcGreen
    color $bcBlue
  }
}

.guest-avatar {
  width 10vw
  height @width
  max-width @width
  max-height @width
  border-radius 50%
  overflow hidden
  position absolute
  top -.5em
  left 0
  margin-right 1.5em
  img {
    width 100%
    height 100%
  }
}

.header {
  width 100%
  display flex
  // padding-left 12vw
  align-items center
  justify-content flex-start
}

.guest-name {
  font-size 1.75em
  font-family 'proxima-nova-condensed'
  color $bcYellow
  // flex 1
}

.guest-status {
  margin-left .5em
  margin-right auto
  span {
    font-size .7em
    display inline-block
    vertical-align middle
    padding .15em .5em
    transform translateY(-.1em)
    margin-left .5em
    font-weight bold
  }
}

.guest-pricing {

}

.guest-hours {
  font-size .9em
  line-height 1.5em
  -webkit-font-smoothing antialiased
  // padding-left 12vw
}

.price {
  font-style italic
  margin-right .5em
}

.label {
  font-style normal
  font-size .75em
  font-weight bold
  color rgba(white,.5)
  margin-right .5em
}

.guest-price-autograph {
  @extends .price
  &:before {
    @extends .label
    content: "Autograph"
  }
}

.guest-price-photo {
  @extends .price
  &:before {
    @extends .label
    content: "Photo"
  }
}

.guest-price-combo {
  @extends .price
  &:before {
    @extends .label
    content: "Combo"
  }
}

body.guidebook {
    overflow: auto;
    .container {
        font-size: .7em;
        overflow: auto;
    }
    .guest {
        display: block;
        margin-bottom: 20px;
    }
    .guest-avatar {
        display: none;
    }
    .header {
        display: block;
        padding-left: 0;
    }
    .guest-status {
        display: inline-block;
        margin-right: 15px;
        padding-left: 0;
        margin-left: 0;
        span {
            margin-left: 0;
        }
    }
    .guest-pricing {
        display: inline-block;
    }
    .guest-hours {
        padding-left: 0;
    }
    .day-hours {
        display: block;
    }
}
