//
// the colors duke, the colors!
//

$bcCyan = rgb(110, 210, 250);
$bcMagenta = rgb(150, 40, 140);
$bcViolet = rgb(90, 55, 145);
$bcBlue = rgb(25, 40, 85);
$bcOrange = rgb(245, 140, 40);

$bcAzure = rgb(60, 135, 200);
$bcYellow = rgb(255, 235, 95);
$bcGreen = rgb(135, 200, 140);
$bcCerulean = rgb(55, 85, 155);
$bcGold = rgb(255, 195, 15);
$bcPurple = rgb(155, 75, 150);
$bcPink = rgb(210, 145, 190);
$bcRust = rgb(255, 100, 30);


//
// fonts
//

$proxima = 'proxima-nova', 'proxima nova', helvetica, sans-serif;

@font-face {
  font-family: 'proxima-nova';
  src:url('/fonts/proxima-nova-regular.eot');
  src:url('/fonts/proxima-nova-regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proxima-nova-regular.woff') format('woff'),
    url('/fonts/proxima-nova-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:url('/fonts/proxima-nova-italic.eot');
  src:url('/fonts/proxima-nova-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proxima-nova-italic.woff') format('woff'),
    url('/fonts/proxima-nova-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src:url('/fonts/proxima-nova-bold.eot');
  src:url('/fonts/proxima-nova-bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proxima-nova-bold.woff') format('woff'),
    url('/fonts/proxima-nova-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:url('/fonts/proxima-nova-bold-italic.eot');
  src:url('/fonts/proxima-nova-bold-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proxima-nova-bold-italic.woff') format('woff'),
    url('/fonts/proxima-nova-bold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova-condensed';
  src:url('/fonts/proxima-nova-condensed-semibold.eot');
  src:url('/fonts/proxima-nova-condensed-semibold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proxima-nova-condensed-semibold.woff') format('woff'),
    url('/fonts/proxima-nova-condensed-semibold.ttf') format('truetype');
  font-weight: 500;
}
